<template>
  <div>
    <HeadImage
      :src="product['image_url']"
      contain
      :action="recentAction"
      :map-zone="product['map_zone']" />

    <div class="c-section c-section--mb24">
      <h1 class="c-title4">
        {{ product['name'] }}
      </h1>

      <p class="c-lead">
        {{ product['description'] }}
      </p>

      <p v-if="product['ec_link_url']" class="c-lead">
        <a
          :href="$customUrlScheme.page('ec', `url=${product['ec_link_url']}`)"
          class="c-textButton c-textButton--right">
          <Icon
            name="cartOff"
            class="c-textButton__icon c-textButton__icon--prepend" />
          {{ WORDS.PRODUCT.PAY_ONLINESTORE }}
        </a>
      </p>
    </div>

    <RateButtons
      v-if="product['map_position']"
      :impression-count="impressionCount"
      @click="createImpression($event)" />

    <div v-else class="pro-coffee-detail-impression-button">
      <div class="c-section">
        <Button style-type="secondary" fluid @click="createImpression(null)">
          {{ WORDS.PRODUCT.SEND_BUTTON_IMPRESSION }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    RateButtons: () => import('@/components/coffeeDetail/RateButtons.vue')
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  setup: (props, context) => {
    const impressionCount = computed(() => {
      return {
        like: props?.product['impression_count'].is_like || 0,
        hate: props?.product['impression_count'].is_hate || 0
      };
    });

    const recentAction = computed(() => {
      if (!props.product?.impressions?.[0]) return;

      const at = props.product?.impressions[0].created_at;
      const isFavorable = props.product?.impressions[0].is_favorable;
      const type =
        isFavorable === null ? 'memo' : isFavorable ? 'like' : 'hate';

      return { type, at };
    });

    const createImpression = (isFavorable) => {
      const payload = {
        is_favorable: isFavorable,
        memo: {
          aroma: null,
          bitterness: null,
          sourness: null,
          sweetness: null,
          richness: null,
          comment: null
        }
      };
      context.emit('create:impression', payload);
    };

    return {
      WORDS,
      impressionCount,
      recentAction,
      createImpression
    };
  }
});
</script>

<style lang="scss" scoped>
.pro-coffee-detail-impression-button::v-deep {
  .c-coffeeCard {
    margin-bottom: 16px;
  }
}
</style>
